import React, { useContext, useState, useEffect } from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby"
import t from '../../services/GetTranslationValue'
import r from '../../services/Router'
import NullIf from "../../services/NullIf"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import { Button, IconButton, } from "@material-ui/core"
import detailStyles from "../game-providers/item.module.scss"
import styles from "./item.module.scss"
import MainSearch from "../../components/main-search/main-search"
import ThemeContext from "../../context/ThemeContext"
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getLocaleAlias, localizeItem } from "../../graphql-assistant/createLocaleTextGetter"

const removeDisabledPaymentMethodFilter = (method, currentLocale) => {
  if (method.disabledLocales) {
    if (method.disabledLocales.some(x => getLocaleAlias(currentLocale) === getLocaleAlias(x))) {
      return false;
    }
  }
  return true;
}

const getGroups = (items, typeTranslation, translations, locale) => {
  let filteredItems = items && items.filter(x => removeDisabledPaymentMethodFilter(x, locale)).filter(x => x.largeIcon && x.largeIcon.asset);

  let iconsArray = filteredItems;

  return (
    iconsArray.map(x => {
      return (
        (x.largeIcon && x.largeIcon.asset) ?
          <a href={'/' + r.get(locale, translations, typeTranslation, localizeItem(x.displayName, [locale])|| x.name)}>
            <Img
              alt={x.name}
              fluid={x.largeIcon.asset.fluid}
              className={`${styles.gameProviderIcon}`}
            >
              {" "}
              {x.name}{" "}
            </Img>
          </a>
          : ''
      )
    })
  )
}

const getItemIcons = (platform, x, translations) => {
  return [platform[x[0]] ?
    <img alt={`${platform.name} ${t.get(translations, `platform-features-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)}`} src={`/../../../icons/game-provider/normal/${x[1]}.svg`} />
    :
    <img alt={`${platform.name} ${t.get(translations, `platform-features-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)}`} src={`/../../../icons/game-provider/disabled/${x[1]}.svg`} />
    ,
  <span className={`${platform[x[0]] ? "" : detailStyles.notAvailable}`}>
    {t.get(translations, `platform-features-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)}
  </span>]
}


const Details = ({ translations, locale, platform }) => {

  const [showAllProviders, setShowAllProviders] = useState(false);
  const [showAllBanking, setShowAllBanking] = useState(false);
  const [providerHide, setProviderHide] = useState(false);
  const [bankingHide, setBankingHide] = useState(false);
  const [providerHeight, setProviderHeight] = useState();
  const [bankingHeight, setBankingHeight] = useState();

  const toggleGameProviders = () => {
    setShowAllProviders(!showAllProviders);
  }
  const toggleBanking = () => {
    setShowAllBanking(!showAllBanking);
  }

  const platformFeatures = [
    ["isWhiteLabel", 'package'],
    ["hasCrypto", 'bitcoin'],
    ["hasLicenceSupport", 'certificate'],
    ["hasCasinoSupport", 'casino'],
    ["hasCasinoPayments", 'wallet'],
    ["hasSportsbook", 'has-sportsbook'],
  ];

  useEffect(() => {
    platform.gameProviders.length > 0 && setProviderHeight(document.querySelector('#providers-collapse .MuiCollapse-wrapper').offsetHeight);
    platform.paymentMethods.length > 0 && setBankingHeight(document.querySelector('#banking-collapse .MuiCollapse-wrapper').offsetHeight);
    if (providerHeight > 120) {
      setProviderHide(true);
    }
    if (bankingHeight > 120) {
      setBankingHide(true);
    }
  }, [providerHeight, bankingHeight]);

  return (
    <section 
      className={`${styles.detailsContainer}`} 
      style={{ "--providers-count": platform.gameProviders.length, "--banks-count": platform.paymentMethods.length }}
    >
      <div className={`${styles.info}`}>
        <div className={`${styles.logoContainer}`}>
          {platform.logo ? <Img
            alt={platform.name}
            fluid={platform.logo.asset.fluid}
            className={`${styles.logo}`}
          >
            {" "}
            {platform.name}{" "}
          </Img> : ''}
        </div>
        <div className={`${styles.infoContainer}`}>
          <div className={`${styles.nameContainer}`}>
            <p className={`${styles.name}`}>{platform.name}</p>
          </div>
          <div className={`${styles.rowContainer}`}>
            <p className={`${styles.infoRow}`}>
              <label>{t.get(translations, 'platform-details-licences')}: </label>
              {platform.licences.map(x => {
                return <Link to={r.get(locale, translations, "licence", x.name)} className={`${styles.detailsLicence}`}>
                  <span>{x.name},</span>
                </Link>
                })
              }
            </p>
          </div>
        </div>
        <div className={`${styles.specifications}`}>
          {platformFeatures.map(x =>
            <div className={`${styles.platformFeatures}`}>
              {getItemIcons(platform, x, translations)}
            </div>
          )}
        </div>
      </div>
      {platform.gameProviders.length > 0 && 
        <div className={`${styles.containerBox}`}>
          <p className={`${styles.containerTitle}`}>{t.get(translations, 'platform-game-providers')}</p>
          <div id="providers-collapse" className={`platform-collapse ${styles.wrapper}`}>
            <Collapse in={showAllProviders} collapsedHeight={100} timeout={'auto'}>
              {getGroups(platform.gameProviders, "gameProvider", translations, locale)}
            </Collapse>
            {providerHide &&
              <IconButton 
                edge="start" 
                onClick={toggleGameProviders} 
                aria-label={showAllProviders ? "show more" : "show less"} 
                className={`${styles.showMoreButton}`}
              >
                {showAllProviders ?
                  <ExpandLessIcon />
                  :
                  <ExpandMoreIcon />
                }
              </IconButton>
            }
          </div>
        </div>
      }
      {platform.paymentMethods.length > 0 &&
        <div className={`${styles.containerBox}`}>
          <p className={`${styles.containerTitle}`}>{t.get(translations, 'platform-banking-options')}</p>
          <div id="banking-collapse" className={`platform-collapse ${styles.wrapper}`}>
            <Collapse in={showAllBanking} collapsedHeight={100} timeout={'auto'}>
              {getGroups(platform.paymentMethods, "depositMethods", translations, locale)}
            </Collapse>
            {bankingHide && 
              <IconButton
                edge="start"
                onClick={toggleBanking}
                aria-label={showAllBanking ? "show more" : "show less"}
                className={`${styles.showMoreButton}`}
              >
                {showAllBanking ?
                  <ExpandLessIcon />
                  :
                  <ExpandMoreIcon />
                }
              </IconButton>
            }
          </div>
        </div>
      }
    </section>
  )
}

const Content = ({ casinos, translations, locale, platform }) => {
  casinos = casinos
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.platform))
    .filter(x => x.platform.name === platform.name)
  return (
    <div>
      <MainSearch locale={locale} bonuses={casinos} />
    </div>
  )
}

const Platform = ({ data, pageContext, location }) => {


  const { alternateLocaleSlugs } = pageContext
  let { translations, locale, bonuses } = useContext(ThemeContext)

  let platform = data.sanityPlatform;
  platform._rawContent = platform._rawContent && platform._rawContent[locale]
  platform._rawHeaderText = platform._rawHeaderText && platform._rawHeaderText[locale]
  platform._rawFooter = platform._rawFooter && platform._rawFooter[locale]
  platform.seoTitle = platform.seoTitle && platform.seoTitle[locale]
  platform.seoMeta = platform.seoMeta && platform.seoMeta[locale]

  let title = platform._rawHeaderText ? platform._rawHeaderText : `${platform.name} ${t.get(translations, 'game-provider-casinos-header')}`;
  let seoTitle = platform.seoTitle ? platform.seoTitle : `${platform.name} ${t.get(translations, 'game-provider-casinos-seotitle')}`;

  return (
    <Layout pageContext={pageContext} location={location} bcDark>
      <SEO location={location} bonuses={bonuses} alternateSlug={alternateLocaleSlugs}
        title={seoTitle} platform={platform} description={platform.seoMeta} />
      <ContentWrapper
        specialHeader={<Details
          translations={translations}
          locale={locale}
          platform={platform}
        />}
        title={platform.h1Tag[locale]}
        header={platform._rawHeaderText}
        body={platform._rawContent}
        footer={platform._rawFooter}
      >
        <Content
          casinos={bonuses}
          translations={translations}
          locale={locale}
          platform={platform}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default Platform
export const query = graphql`
  query PlatformQuery($platformName: String!) {
    sanitySilo (_id: {eq: "6c6a3bda-364c-482e-a153-4ecd7a12c9de"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "Platforms" }
    ) {
      headingFilters {
        ...LocaleTranslation
      }
    }
    sanityPlatform(name: { eq: $platformName }) {
      seoMeta {
        ...LocaleString
      }
      seoTitle {
        ...LocaleString
      }
      _rawContent(resolveReferences: {maxDepth: 15})
      _rawFooter(resolveReferences: {maxDepth: 15})
      _rawHeaderText(resolveReferences: {maxDepth: 15})
      _updatedAt
      logo {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      licences {
        name
        icon {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      name
      hasSportsbook
      hasLicenceSupport
      hasCrypto
      hasCasinoSupport
      hasCasinoPayments
      isWhiteLabel
      paymentMethods {
        name
        disabledLocales
        displayName {
            ...LocaleString
        }
        largeIcon {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      gameProviders {
        name
        largeIcon {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      h1Tag {
        fi
        enGB
        enCA
        en
      }
    }
  }
`